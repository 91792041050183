.article_update {
  border-radius: 4px;
  margin-bottom: 15px;
  background: #eee;
  padding: 2px; }
  .article_update.article_update--last {
    background: #c7a652; }
  .article_update .article_update__title {
    line-height: 30px;
    font-size: 0.83rem;
    font-weight: 600; }
  .article_update .article_update__content {
    padding: 11px 15px 14px;
    font-size: 0.9rem;
    line-height: 1.6em;
    border-radius: 2px;
    background: white; }
    .article_update .article_update__content p {
      margin: 0; }
