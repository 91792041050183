.navigation {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 100;
  width: 100%;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  opacity: 0;
  height: calc(100vh - 70px);
  visibility: collapse;
  -webkit-transition: opacity 0.2s linear 0.2s, visibility 0s linear 0.4s;
  transition: opacity 0.2s linear 0.2s, visibility 0s linear 0.4s;
  overflow: scroll; }
  @media (min-width: 768px) {
    .navigation {
      display: block !important;
      visibility: visible;
      position: relative;
      -webkit-transition: none;
      transition: none;
      opacity: 1;
      height: 45px;
      background: #fff;
      overflow: unset; } }
  .navigation .navigation__list {
    background: #fff;
    list-style: none;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0 0 2px 2px;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.3s ease-out, padding-top 0.2s ease-out 0.1s, padding-bottom 0.2s ease-out 0.1s;
    transition: max-height 0.3s ease-out, padding-top 0.2s ease-out 0.1s, padding-bottom 0.2s ease-out 0.1s;
    padding-left: 15px;
    padding-right: 15px;
    margin: auto; }
    @media (min-width: 768px) {
      .navigation .navigation__list {
        max-width: 720px; } }
    @media (min-width: 1024px) {
      .navigation .navigation__list {
        max-width: 990px; } }
    @media (min-width: 1200px) {
      .navigation .navigation__list {
        max-width: 1140px; } }
    @media (min-width: 768px) {
      .navigation .navigation__list {
        padding: 0;
        -webkit-transition: none;
        transition: none;
        max-height: unset;
        height: 45px;
        overflow: unset; } }
  .navigation.navigation--mobile-open {
    display: block;
    opacity: 100;
    visibility: visible;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s; }
    .navigation.navigation--mobile-open .navigation__list {
      -webkit-transition: max-height 0.5s ease-out, padding-top 0.2s ease-out, padding-bottom 0.2s ease-out;
      transition: max-height 0.5s ease-out, padding-top 0.2s ease-out, padding-bottom 0.2s ease-out;
      max-height: 1000px;
      padding-top: 10px;
      padding-bottom: 10px; }
  .navigation .navigation__element {
    font: 600 1rem/35px "Ek Mukta", sans-serif;
    padding: 5px 0;
    position: relative;
    letter-spacing: 0.5px; }
    .navigation .navigation__element::before {
      width: 16px;
      height: 16px;
      background: url(/theme/spacex/assets/2e24a27bdba801385be2b8b51812ae7f.svg);
      background-size: contain;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      position: absolute;
      bottom: -16px;
      margin-left: -11.3px;
      left: 50%;
      -webkit-filter: drop-shadow(-1px 1px 0.3px rgba(66, 65, 65, 0.4));
              filter: drop-shadow(-1px 1px 0.3px rgba(66, 65, 65, 0.4)); }
    .navigation .navigation__element.navigation__element--tool {
      float: right;
      line-height: 43px;
      padding: 2px 0 0;
      color: #999;
      margin-left: 5px;
      display: none;
      font-size: 0.83rem; }
      @media (min-width: 768px) {
        .navigation .navigation__element.navigation__element--tool {
          display: block; } }
      .navigation .navigation__element.navigation__element--tool .navigation__link {
        height: auto;
        padding: 0 10px;
        color: #ccc;
        -webkit-transition: 0.5s;
        transition: 0.5s; }
        .navigation .navigation__element.navigation__element--tool .navigation__link:hover {
          color: #00548d; }
    @media (min-width: 768px) {
      .navigation .navigation__element {
        font: 400 1rem/47px "Ek Mukta", sans-serif;
        padding: 0;
        float: left;
        letter-spacing: 0; }
        .navigation .navigation__element:first-child {
          margin-left: -15px; }
        .navigation .navigation__element--active::before {
          content: ' '; } }
    @media (min-width: 1024px) {
      .navigation .navigation__element {
        letter-spacing: 0.5px; } }
  .navigation .navigation__link {
    color: #333;
    display: block;
    padding: 0 5px;
    text-decoration: none;
    -webkit-transition: 0.2s;
    transition: 0.2s; }
    @media (min-width: 768px) {
      .navigation .navigation__link {
        padding: 0 15px;
        height: 45px; }
        .navigation .navigation__link:hover {
          color: #c7a652; } }
    .navigation .navigation__link--live {
      color: #b70500; }
    .navigation .navigation__link--active {
      background: #9eaab7;
      border-radius: 4px;
      padding-left: 15px;
      color: white; }
      @media (min-width: 768px) {
        .navigation .navigation__link--active {
          background: white;
          color: #333; } }
  .navigation .navigation__icon {
    padding-right: 5px; }
    @media (min-width: 768px) {
      .navigation .navigation__icon {
        display: none; } }
  .navigation .navigation__element--mobile {
    display: block; }
    @media (min-width: 768px) {
      .navigation .navigation__element--mobile {
        display: none; } }
