.widget_trailer .widget_trailer__thumbnail {
  display: block;
  background: #fafafa;
  overflow: hidden;
  border-bottom: 1px solid #eee; }

.widget_trailer .widget_trailer__image {
  background: 50% 50% no-repeat;
  background-size: contain; }
  .widget_trailer .widget_trailer__image--cover {
    background-size: cover;
    height: 0;
    padding-bottom: calc(68.75% - 20px); }
  .widget_trailer .widget_trailer__image--logo {
    background-size: contain;
    height: 0;
    padding-bottom: calc(68.75% - 20px);
    margin: 10px 0; }

.widget_trailer .widget_trailer__content {
  font-size: 0.75rem;
  line-height: 1.4em;
  margin: 0 15px 15px; }
  .widget_trailer .widget_trailer__content p {
    margin: 0 0 4px 0; }

.widget_trailer .widget_trailer__timer {
  display: block;
  margin-top: -1px; }

.widget_trailer .widget_trailer__name {
  font: 300 1.25rem/1.6rem "Ek Mukta", sans-serif;
  padding: 25px 15px 15px;
  color: #424242;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  display: block;
  text-align: center; }
  .widget_trailer .widget_trailer__name:hover {
    opacity: 0.8; }

.widget_trailer .widget_trailer__info {
  position: relative;
  padding: 0 15px; }
