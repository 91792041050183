.gdpr {
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  -webkit-box-shadow: 0 -1px 2px 0 rgba(66, 65, 65, 0.4);
          box-shadow: 0 -1px 2px 0 rgba(66, 65, 65, 0.4);
  display: none; }
  .gdpr--enabled {
    display: block; }
  .gdpr .gdpr__container {
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
    padding-top: 10px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 60vh; }
    @media (min-width: 768px) {
      .gdpr .gdpr__container {
        max-width: 720px; } }
    @media (min-width: 1024px) {
      .gdpr .gdpr__container {
        max-width: 990px; } }
    @media (min-width: 1200px) {
      .gdpr .gdpr__container {
        max-width: 1140px; } }
    @media (min-width: 1024px) {
      .gdpr .gdpr__container {
        height: 40vh; } }
  .gdpr .gdpr__title {
    font-family: "Ek Mukta", sans-serif;
    font-size: 1.25rem;
    font-weight: normal; }
  .gdpr .gdpr__close {
    position: absolute;
    right: 10px;
    top: 18px;
    font-size: 2em;
    cursor: pointer;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    color: #424242; }
    .gdpr .gdpr__close:hover {
      color: #5c5c5c; }
  .gdpr .gdpr__info {
    font-size: 0.9em;
    overflow-y: scroll;
    padding: 10px;
    margin: 0 0 20px;
    border: 1px solid #eee;
    border-radius: 4px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
    .gdpr .gdpr__info p {
      margin: 0 0 5px; }
