.mission_table {
  font-size: 0.7rem;
  width: 100%;
  display: table; }
  .mission_table.mission_table--striped {
    line-height: 1.8em; }
    .mission_table.mission_table--striped tr {
      -webkit-transition: 0.5s background;
      transition: 0.5s background; }
      .mission_table.mission_table--striped tr:nth-child(2n-1) {
        background: #f9f9f9; }
    .mission_table.mission_table--striped td {
      padding: 5px 10px; }
  .mission_table td {
    padding: 3px 0; }
  .mission_table .mission_table__row {
    display: table-row; }
  .mission_table .mission_table__label {
    font-weight: bolder;
    vertical-align: top;
    width: 40%;
    display: table-cell; }
  .mission_table .mission_table__value {
    vertical-align: top;
    font-weight: normal;
    margin-bottom: 5px;
    width: unset;
    display: table-cell; }
  .mission_table .mission_table__map {
    color: #c7a652; }
  .mission_table .mission_table__reuse {
    vertical-align: text-bottom; }
