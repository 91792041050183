.separator {
  height: 15px; }
  .separator.separator--vl {
    height: 45px; }
  .separator.separator--lg {
    height: 30px; }
  .separator.separator--md {
    height: 15px; }
  .separator.separator--sm {
    height: 10px; }
  .separator.separator--vs {
    height: 5px; }
