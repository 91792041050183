.interview {
  padding: 15px;
  font-size: 0.9rem;
  line-height: 1.6em;
  margin-left: 60px;
  position: relative;
  background: #fff; }
  .interview br {
    display: block;
    content: " ";
    margin-top: 3px; }
  .interview ul, .interview ol {
    margin-top: 10px !important; }
  .interview.interview--question {
    margin-top: 20px;
    border: 1px solid #eee;
    background: #f9f9f9;
    border-radius: 4px 4px 0 0;
    z-index: 2; }
  .interview.interview--answer {
    border: 1px solid #eee;
    border-radius: 0 0 4px 4px;
    margin-top: -1px;
    z-index: 1; }
    .interview.interview--answer + .interview--question {
      margin-top: 15px; }
  .interview .interview__author {
    font-weight: bolder;
    display: inline-block;
    font-size: 0.75rem; }
  .interview .interview__photo {
    width: 50px !important;
    height: auto !important;
    position: absolute;
    top: 10px;
    left: -60px;
    z-index: 3;
    border-radius: 4px !important; }
