.author {
  background: #fff;
  border-radius: 2px;
  padding: 30px;
  margin-bottom: 30px;
  display: grid;
  grid-template-areas: "photo" "title" "info" "links";
  grid-template-columns: auto;
  grid-auto-rows: auto; }
  @media (min-width: 1024px) {
    .author {
      grid-template-areas: "photo title" "info info" "links links";
      grid-template-columns: 180px auto; } }
  .author .author__photo {
    grid-area: photo; }
  .author .author__title {
    grid-area: title;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .author .author__name {
    font-family: "Ek Mukta", sans-serif;
    font-size: 2.5rem;
    line-height: 1em;
    text-align: center;
    margin-top: 20px; }
    @media (min-width: 1024px) {
      .author .author__name {
        text-align: left;
        margin-top: 0; } }
  .author .author__summary {
    margin-top: 5px;
    max-width: 300px;
    font-style: italic;
    text-align: center; }
    @media (min-width: 1024px) {
      .author .author__summary {
        text-align: left; } }
  .author .author__info {
    grid-area: info;
    color: #666;
    font-size: 0.9rem;
    margin: 20px -15px 0;
    background: #fafafa;
    border-radius: 4px;
    padding: 0 15px; }
  .author .author__links {
    grid-area: links;
    margin-bottom: -5px;
    margin-top: 20px; }
  .author .author__photo_image {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    margin: auto; }
    @media (min-width: 1024px) {
      .author .author__photo_image {
        margin: unset; } }
