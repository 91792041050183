.website_layout {
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  padding-top: 20px;
  position: relative;
  z-index: 2;
  max-width: 100%;
  display: grid;
  grid-template-areas: "content" "sidebar";
  grid-template-columns: 100%; }
  @media (min-width: 768px) {
    .website_layout {
      max-width: 720px; } }
  @media (min-width: 1024px) {
    .website_layout {
      max-width: 990px; } }
  @media (min-width: 1200px) {
    .website_layout {
      max-width: 1140px; } }
  @media (min-width: 1024px) {
    .website_layout {
      padding-top: 10px;
      grid-template-areas: "content sidebar";
      grid-template-columns: 70% auto;
      grid-gap: 20px; } }
  @media (min-width: 1200px) {
    .website_layout {
      grid-template-columns: 72% auto; } }
  @media (min-width: 768px) {
    .website_layout {
      padding-top: 20px; } }
  .website_layout .website_layout__content {
    grid-area: content; }
  .website_layout .website_layout__sidebar {
    grid-area: sidebar;
    margin-bottom: 15px; }
    .website_layout .website_layout__sidebar::before {
      content: " ";
      background: #e2e2e2;
      height: 1px;
      display: block;
      margin: 5px -15px 20px; }
      @media (min-width: 1024px) {
        .website_layout .website_layout__sidebar::before {
          content: none; } }
    @media (min-width: 1024px) {
      .website_layout .website_layout__sidebar {
        margin-bottom: 220px; } }
