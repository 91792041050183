.empty {
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Ek Mukta", sans-serif;
  font-weight: 500;
  font-size: 1.1375rem;
  color: #999;
  background: #f9f9f9;
  border-radius: 4px; }
  .empty.empty--hidden {
    display: none; }
