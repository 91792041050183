.live_player {
  background: #1f262b; }
  @media (min-width: 1024px) {
    .live_player {
      background: linear-gradient(90deg, #1c2226 9px, transparent 1%) center, linear-gradient(#1c2226 9px, transparent 1%) center, #444;
      background-size: 10px 10px; } }
  .live_player .live_player__container {
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
    padding: 25px 15px 25px; }
    @media (min-width: 768px) {
      .live_player .live_player__container {
        max-width: 720px; } }
    @media (min-width: 1024px) {
      .live_player .live_player__container {
        max-width: 990px; } }
    @media (min-width: 1200px) {
      .live_player .live_player__container {
        max-width: 1140px; } }
    @media (min-width: 1024px) {
      .live_player .live_player__container {
        padding: 40px 0 55px; } }
  .live_player .live_player__embed {
    position: relative;
    height: 0;
    margin: 0 -10px;
    padding-bottom: 56.25%;
    -webkit-box-shadow: 0 3px 3px 0 #111;
            box-shadow: 0 3px 3px 0 #111; }
    .live_player .live_player__embed iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
  .live_player .live_player__title {
    color: white;
    font-family: "Ek Mukta", sans-serif;
    font-size: 1.25rem;
    line-height: 1.2em;
    margin-bottom: 5px; }
    @media (min-width: 1024px) {
      .live_player .live_player__title {
        font-size: 2rem; } }
  .live_player .live_player__details {
    font-size: 0.83rem;
    color: white;
    margin-bottom: 25px;
    color: #9eaab7; }
    .live_player .live_player__details a {
      color: #667789;
      -webkit-transition: color 0.5s;
      transition: color 0.5s; }
      .live_player .live_player__details a:hover {
        color: #8190a1; }
