.information {
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  margin: 30px auto; }
  @media (min-width: 768px) {
    .information {
      max-width: 720px; } }
  @media (min-width: 1024px) {
    .information {
      max-width: 990px; } }
  @media (min-width: 1200px) {
    .information {
      max-width: 1140px; } }
  .information .information__container {
    padding: 50px;
    text-align: center;
    background: #fff;
    -webkit-box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
            box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-height: 30vh; }
    @media (min-width: 1024px) {
      .information .information__container {
        min-height: 50vh; } }
  .information .information__header {
    font-family: "Ek Mukta", sans-serif, serif;
    line-height: 1.1em;
    font-size: 2.5rem;
    margin-bottom: 15px; }
  .information .information__note {
    font-family: "Lato", sans-serif;
    font-size: 1.25rem;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.5em; }
    @media (min-width: 768px) {
      .information .information__note {
        font-size: 1.25rem; } }
  .information .information__action {
    margin-top: 5px;
    font-weight: lighter;
    font-size: 1.1375rem; }
