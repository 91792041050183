.pagination {
  margin: 15px auto 45px;
  text-align: center; }
  .pagination .pagination__list {
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0;
    display: inline-block;
    height: 48px;
    border-radius: 24px;
    background: #fff; }
  .pagination .pagination__element {
    width: 40px;
    height: 40px;
    margin: 4px;
    font-size: 0.83rem;
    font-weight: bolder;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 20px; }
    .pagination .pagination__element:not(.pagination__element--disabled):hover {
      background: #d9dde2; }
    .pagination .pagination__element--active {
      background: #9eaab7; }
      .pagination .pagination__element--active .pagination__label {
        color: white; }
    .pagination .pagination__element--inactive {
      display: none; }
      @media (min-width: 1024px) {
        .pagination .pagination__element--inactive {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex; } }
    .pagination .pagination__element--disabled, .pagination .pagination__element--disabled:hover {
      color: #999;
      display: none; }
      @media (min-width: 1024px) {
        .pagination .pagination__element--disabled, .pagination .pagination__element--disabled:hover {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex; } }
    .pagination .pagination__element--step .pagination__label {
      color: #424242; }
    .pagination .pagination__element--jump {
      display: none; }
      @media (min-width: 1024px) {
        .pagination .pagination__element--jump {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex; } }
  .pagination .pagination__label {
    color: #424242;
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center; }
  .pagination .pagination__switch {
    display: block;
    font-size: 0.83rem;
    text-decoration: none;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    background: #00548d;
    padding: 8px 16px;
    border-radius: 2px;
    color: white; }
    .pagination .pagination__switch:hover {
      opacity: 0.8; }
    .pagination .pagination__switch--prev {
      float: left; }
      .pagination .pagination__switch--prev::before {
        content: "\2190 "; }
    .pagination .pagination__switch--next {
      float: right; }
      .pagination .pagination__switch--next::after {
        content: " \2192"; }
