.message {
  margin: 10px 0;
  padding: 10px 10px 10px 15px;
  color: black;
  font-size: 0.83rem;
  line-height: 1.6em;
  border: 1px solid transparent;
  border-radius: 2px; }
  @media (min-width: 1024px) {
    .message {
      border-width: 1px 1px 1px 5px; } }
  .message.message--info {
    background: #a8d8ff;
    border-color: #8fcdff; }
  .message.message--success {
    background: #81c784;
    border-color: #6fbf73; }
  .message.message--alert {
    background: #dfec96;
    border-color: #c5dc40; }
  .message.message--danger {
    background: #e57373;
    border-color: #e15d5d; }
