.time_counter {
  font-size: 16px;
  color: #c7a652;
  background: url(/theme/spacex/assets/1afd0c96a8bb555ee5f42fe22772adbd.png) 0 -1px #181c1f;
  text-align: center;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 5px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Share Tech Mono", monospace;
  text-shadow: 0 0 10px #000; }
  .time_counter.time_counter--link:hover {
    background: #0063a7;
    text-decoration: none; }
  .time_counter .time_counter__text {
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s; }

@-webkit-keyframes pulse {
  0% {
    text-shadow: 0 0 0 rgba(199, 166, 82, 0); }
  30% {
    text-shadow: 0 0 10px #c7a652; }
  100% {
    text-shadow: 0 0 0 rgba(199, 166, 82, 0); } }

@keyframes pulse {
  0% {
    text-shadow: 0 0 0 rgba(199, 166, 82, 0); }
  30% {
    text-shadow: 0 0 10px #c7a652; }
  100% {
    text-shadow: 0 0 0 rgba(199, 166, 82, 0); } }
  .time_counter .time_counter__text--pulse {
    text-shadow: 0 0 rgba(199, 166, 82, 0.9); }
