.author_links {
  list-style: none;
  font-size: 0.83rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0;
  padding: 0; }
  .author_links .author_links__item {
    display: block;
    margin-bottom: 5px;
    margin-right: 15px; }
    .author_links .author_links__item a {
      padding-left: 1px;
      color: #424242; }
    .author_links .author_links__item:last-child {
      padding-bottom: 0; }
