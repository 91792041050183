.message {
  margin: 10px 0;
  padding: 10px 10px 10px 15px;
  color: black;
  font-size: 0.83rem;
  line-height: 1.6em;
  border: 1px solid transparent;
  border-radius: 2px; }
  @media (min-width: 1024px) {
    .message {
      border-width: 1px 1px 1px 5px; } }
  .message.message--info {
    background: #a8d8ff;
    border-color: #8fcdff; }
  .message.message--success {
    background: #81c784;
    border-color: #6fbf73; }
  .message.message--alert {
    background: #dfec96;
    border-color: #c5dc40; }
  .message.message--danger {
    background: #e57373;
    border-color: #e15d5d; }

.read_more {
  display: block;
  margin: 25px 0;
  padding: 10px 10px 10px 15px;
  color: black;
  font-size: 0.9rem;
  line-height: 1.6em;
  border-radius: 2px;
  background: #00548d;
  border: 1px solid #00548d;
  color: white !important;
  text-align: center;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s; }
  .read_more:hover {
    background: #0072c0; }

.interview {
  padding: 15px;
  font-size: 0.9rem;
  line-height: 1.6em;
  margin-left: 60px;
  position: relative;
  background: #fff; }
  .interview br {
    display: block;
    content: " ";
    margin-top: 3px; }
  .interview ul, .interview ol {
    margin-top: 10px !important; }
  .interview.interview--question {
    margin-top: 20px;
    border: 1px solid #eee;
    background: #f9f9f9;
    border-radius: 4px 4px 0 0;
    z-index: 2; }
  .interview.interview--answer {
    border: 1px solid #eee;
    border-radius: 0 0 4px 4px;
    margin-top: -1px;
    z-index: 1; }
    .interview.interview--answer + .interview--question {
      margin-top: 15px; }
  .interview .interview__author {
    font-weight: bolder;
    display: inline-block;
    font-size: 0.75rem; }
  .interview .interview__photo {
    width: 50px !important;
    height: auto !important;
    position: absolute;
    top: 10px;
    left: -60px;
    z-index: 3;
    border-radius: 4px !important; }

.form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 20px 15px;
  font-family: "Lato", sans-serif;
  background: #fafafa;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 10px -15px 15px; }
  .form label {
    font-size: 0.83rem;
    line-height: 1.2em;
    font-weight: bolder;
    margin-bottom: 5px; }
  .form input, .form textarea {
    border: 1px solid #e5e5e5;
    border-bottom-width: 2px;
    -webkit-appearance: none;
    font-family: "Lato", sans-serif;
    font-size: 0.9rem;
    -webkit-transition: border-color 0.5s;
    transition: border-color 0.5s;
    padding: 15px;
    border-radius: 2px;
    margin-bottom: 15px;
    min-width: 400px;
    max-width: 100%;
    line-height: 1.6em; }
    .form input::-webkit-input-placeholder, .form textarea::-webkit-input-placeholder {
      color: #999; }
    .form input::-moz-placeholder, .form textarea::-moz-placeholder {
      color: #999; }
    .form input:-ms-input-placeholder, .form textarea:-ms-input-placeholder {
      color: #999; }
    .form input::-ms-input-placeholder, .form textarea::-ms-input-placeholder {
      color: #999; }
    .form input::placeholder, .form textarea::placeholder {
      color: #999; }
    .form input:focus, .form textarea:focus {
      border-color: #9eaab7;
      outline: none; }
  .form textarea {
    width: calc(100% - 30px);
    height: 200px; }
  .form input[type="submit"] {
    background: #00548d;
    border-radius: 2px;
    border: none;
    color: white;
    padding: 10px;
    min-width: 150px;
    margin-bottom: 0;
    -webkit-transition: background-color 0.5s;
    transition: background-color 0.5s;
    cursor: pointer; }
    .form input[type="submit"]:focus, .form input[type="submit"]:hover {
      background: #0072c0;
      outline: none; }

.page {
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
          box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
  border-top: 2px solid #00548d;
  border-radius: 2px;
  margin-bottom: 15px;
  overflow: hidden; }
  .page.page--flat {
    border-top: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    margin-bottom: 20px; }
    @media (min-width: 1024px) {
      .page.page--flat {
        padding: 0 15px; } }
    .page.page--flat .page__title {
      font-size: 1.4rem;
      margin-top: 25px;
      margin-bottom: 5px; }
      @media (min-width: 1024px) {
        .page.page--flat .page__title {
          margin-top: 30px; } }
    .page.page--flat .page__head {
      margin-bottom: 10px; }
    .page.page--flat .page__container {
      padding-bottom: 10px; }
      @media (min-width: 1024px) {
        .page.page--flat .page__container {
          padding-bottom: 15px; } }
  .page .page__container {
    padding: 0 15px 15px; }
  .page .page__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 10px; }
  .page .page__labels {
    position: absolute;
    z-index: 20;
    top: 0;
    right: 0;
    margin: 0;
    line-height: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.6));
            filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.6)); }
    @media (min-width: 1024px) {
      .page .page__labels {
        -webkit-filter: none;
                filter: none; } }
  .page .page__title {
    font: 400 1.75rem/1.1em "Ek Mukta", sans-serif;
    margin: 30px 0 10px;
    color: #333;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
    @media (min-width: 1024px) {
      .page .page__title {
        font: 400 2.5rem/1.2em "Ek Mukta", sans-serif;
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
        margin: 50px 0 5px; } }
  .page .page__date {
    color: #444a53;
    font: 400 0.75rem "Lato", sans-serif;
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
    @media (min-width: 1024px) {
      .page .page__date {
        -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
                order: 3; } }
  .page .page__microdata {
    display: none; }
  .page .page__image {
    width: calc(100% + 30px);
    margin: 0 -15px 0;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
    @media (min-width: 1024px) {
      .page .page__image {
        -webkit-box-ordinal-group: 5;
            -ms-flex-order: 4;
                order: 4;
        margin: 25px -15px 0; } }
    .page .page__image img {
      display: block;
      width: 100%;
      cursor: pointer;
      margin: 0;
      -webkit-transition: opacity 0.5s;
      transition: opacity 0.5s; }
      .page .page__image img:hover {
        opacity: 0.75; }
      .page .page__image img.logo {
        padding: 0 15px;
        width: calc(100% - 30px); }
  .page .page__image_label {
    font-style: italic;
    font-size: 0.75rem;
    line-height: 1.6em;
    color: #444a53;
    margin: 5px 15px;
    display: none; }
    @media (min-width: 1024px) {
      .page .page__image_label {
        -webkit-box-ordinal-group: 5;
            -ms-flex-order: 4;
                order: 4;
        margin: 5px 15px 10px;
        display: block; } }
  .page .page__publisher {
    display: none; }
  .page .page__content {
    margin: 0;
    font: 400 1rem/1.7em "Lato", sans-serif;
    color: #424242;
    display: block;
    text-align: left; }
    .page .page__content h1,
    .page .page__content h2,
    .page .page__content h3,
    .page .page__content h4,
    .page .page__content h5,
    .page .page__content h6 {
      font-weight: normal;
      margin-bottom: 0.5em;
      font-family: "Ek Mukta", sans-serif;
      color: #333; }
    .page .page__content p {
      margin: 10px 0; }
    .page .page__content li p {
      margin: 0; }
    .page .page__content h1 {
      font-size: 1.6em;
      margin-top: 25px;
      margin-bottom: 5px; }
    .page .page__content h2 {
      font-size: 1.3em;
      margin-top: 18px;
      margin-bottom: 5px; }
    .page .page__content h3 {
      font-size: 1.2em;
      margin-top: 15px;
      margin-bottom: 5px; }
    .page .page__content h4 {
      font-size: 1.1em;
      margin-top: 10px;
      margin-bottom: 5px; }
    .page .page__content h1 + p, .page .page__content h2 + p, .page .page__content h3 + p, .page .page__content h4 + p {
      margin-top: 5px; }
    .page .page__content a {
      color: #0072c0;
      -webkit-transition: 0.3s;
      transition: 0.3s; }
      .page .page__content a:hover {
        color: #00548d; }
    .page .page__content ol {
      margin-top: -5px; }
      .page .page__content ol ol {
        margin-top: 5px; }
    .page .page__content ul {
      margin-top: -5px;
      padding-left: 25px;
      list-style: square; }
      .page .page__content ul ul {
        margin-top: 5px;
        margin-bottom: 5px; }
      @media (min-width: 768px) {
        .page .page__content ul {
          padding-left: 40px; } }
    .page .page__content ul.menu {
      border: 1px solid #eee;
      border-radius: 2px;
      background: #fafafa;
      padding: 10px 35px 10px 35px;
      font-size: 0.9rem;
      display: inline-block;
      margin: 10px 0 0; }
    .page .page__content table {
      border-spacing: 0;
      border-radius: 3px;
      text-align: left;
      font-size: 0.83rem;
      line-height: 1.5em;
      margin: 20px auto; }
      .page .page__content table.scroll {
        display: block;
        overflow: scroll; }
      .page .page__content table th, .page .page__content table td {
        padding: 5px; }
      .page .page__content table td {
        vertical-align: top; }
      .page .page__content table th, .page .page__content table td {
        border-top: 1px solid #e0e0e0; }
      .page .page__content table tr:hover {
        background: #fafafa; }
      .page .page__content table thead tr:last-child th {
        border-bottom: 1px solid #e0e0e0; }
      .page .page__content table tbody tr:last-child td {
        border-bottom: 1px solid #e0e0e0; }
    .page .page__content figure.thumb {
      margin: 20px -15px 18px; }
      @media (max-width: 767px) {
        .page .page__content figure.thumb {
          width: calc(100% + 30px) !important;
          float: none !important; } }
      @media (min-width: 768px) {
        .page .page__content figure.thumb {
          max-width: 100%; } }
      .page .page__content figure.thumb img {
        margin: 0;
        display: block;
        border-radius: 0;
        width: 100%; }
        @media (min-width: 768px) {
          .page .page__content figure.thumb img {
            border-radius: 2px; } }
      .page .page__content figure.thumb figcaption {
        font-size: 0.75rem;
        color: #666;
        line-height: 1.5em;
        padding: 0 1px;
        margin: 7px 15px 0; }
        @media (min-width: 768px) {
          .page .page__content figure.thumb figcaption {
            margin: 7px 0; } }
      @media (min-width: 768px) {
        .page .page__content figure.thumb.centered {
          margin: 20px auto 7px; } }
    @media (min-width: 768px) {
      .page .page__content img[style*="float: right"], .page .page__content img[style*="float:right"],
      .page .page__content figure.thumb[style*="float: right"],
      .page .page__content figure.thumb[style*="float:right"] {
        margin: 5px 0 5px 20px; }
      .page .page__content img[style*="float: left"], .page .page__content img[style*="float:left"],
      .page .page__content figure.thumb[style*="float: left"],
      .page .page__content figure.thumb[style*="float:left"] {
        margin: 5px 20px 5px 0; } }
    .page .page__content img {
      max-width: 100%;
      border-radius: 2px; }
      .page .page__content img.centered {
        display: block;
        margin: 20px auto; }
      .page .page__content img.gallery {
        cursor: pointer; }
        @media (min-width: 1024px) {
          .page .page__content img.gallery {
            -webkit-transition: opacity 0.5s;
            transition: opacity 0.5s; }
            .page .page__content img.gallery:hover {
              opacity: 0.75; } }
    .page .page__content blockquote {
      position: relative;
      font-size: 0.83rem;
      line-height: 1.4em;
      text-align: left;
      padding: 0 0 0 20px;
      margin: 30px 15px; }
      @media (min-width: 1024px) {
        .page .page__content blockquote {
          padding: 0 30px;
          margin: 30px; } }
      .page .page__content blockquote p {
        font-size: 1.25rem;
        line-height: 1.5em; }
      .page .page__content blockquote .quote_author {
        display: block;
        margin-top: -5px;
        font-size: 0.83rem;
        line-height: 1.4em; }
      .page .page__content blockquote::before {
        content: "\f10e";
        font-family: "Font Awesome 5 Free", serif;
        font-weight: 900;
        position: absolute;
        left: 0;
        top: -5px;
        color: #9eaab7; }
    .page .page__content hr {
      border-top: 1px solid #eee;
      border-bottom: none; }
    .page .page__content .embeddedContent {
      margin: 20px 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .page .page__content .embeddedContent.oembed-provider-twitter .twitter-tweet {
        margin: 0 auto !important; }
        .page .page__content .embeddedContent.oembed-provider-twitter .twitter-tweet iframe {
          max-width: 100%; }
      .page .page__content .embeddedContent.oembed-provider-youtube {
        position: relative;
        height: 0;
        padding-bottom: 56.25%; }
        .page .page__content .embeddedContent.oembed-provider-youtube iframe {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%; }
    .page .page__content .interview.interview--answer + p {
      margin-top: 20px; }
    .page .page__content .sources {
      font-size: 0.83rem;
      margin: 5px 0 10px; }
      .page .page__content .sources a {
        color: #444a53; }
    @media (max-width: 1023px) {
      .page .page__content > .article_update:first-child {
        margin-top: 20px; } }
    .page .page__content.page__content--reduced {
      font-size: 0.9rem;
      line-height: 1.6em; }
  .page .page__truncate {
    content: "Nie oszukuj :(";
    max-height: 800px;
    overflow: hidden;
    position: relative; }
    .page .page__truncate::before {
      content: " ";
      position: absolute;
      bottom: 0;
      height: 300px;
      width: 100%;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, rgba(255, 255, 255, 0)), color-stop(95%, white));
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, white 95%); }
  .page .page__tags {
    background: #fafafa;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 15px;
    margin: 10px -15px 0; }
    .page .page__tags .page__section_title {
      margin: 3px 0 15px;
      color: #16181b; }
  .page .page__section_title {
    color: #444a53;
    margin: 0 0 10px 0;
    font-weight: bolder;
    font-size: 0.83rem; }
  .page .page__authors {
    overflow: hidden;
    margin: 20px 0 5px; }
  .page .page__authors_grid {
    display: grid;
    grid-template-areas: "author";
    grid-gap: 10px;
    grid-auto-rows: auto;
    grid-template-columns: 1fr; }
    @media (min-width: 768px) {
      .page .page__authors_grid {
        grid-template-areas: "author author";
        grid-template-columns: 1fr 1fr; } }
    @media (min-width: 1200px) {
      .page .page__authors_grid {
        grid-template-areas: "author author author";
        grid-template-columns: 1fr 1fr 1fr; } }
