.author_box {
  display: grid;
  grid-template-areas: "thumb details";
  grid-template-rows: 50px;
  grid-template-columns: 50px 1fr;
  grid-gap: 5px;
  border-radius: 35px;
  padding: 10px;
  cursor: pointer;
  -webkit-transition: 0.5s background-color;
  transition: 0.5s background-color;
  overflow: hidden; }
  @media (min-width: 1024px) {
    .author_box:hover {
      background: #eee; } }
  .author_box .author_box__image {
    display: block;
    grid-area: thumb;
    height: 50px;
    width: 50px;
    background-size: cover;
    background-position: center center;
    border-radius: 25px;
    margin: auto; }
  .author_box .author_box__details {
    grid-area: details;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: left;
    padding: 5px; }
  .author_box .author_box__name {
    font-weight: bold;
    font-size: 0.83rem;
    line-height: 1.5em;
    color: #424242; }
  .author_box .author_box__summary {
    font-size: 0.75rem;
    color: #666;
    line-height: 1.2em; }
