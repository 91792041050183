.jumbotron {
  background: url(/theme/spacex/assets/f7e256df1f1cfda5107a353956e7585a.png) #1f3a4c; }
  @media (min-width: 1024px) {
    .jumbotron {
      display: block; } }
  .jumbotron .jumbotron__container {
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
    padding: 0; }
    @media (min-width: 768px) {
      .jumbotron .jumbotron__container {
        max-width: 720px; } }
    @media (min-width: 1024px) {
      .jumbotron .jumbotron__container {
        max-width: 990px; } }
    @media (min-width: 1200px) {
      .jumbotron .jumbotron__container {
        max-width: 1140px; } }
    @media (min-width: 1024px) {
      .jumbotron .jumbotron__container {
        padding: 30px 0; } }
  .jumbotron .jumbotron__viewport {
    height: 300px;
    background-size: cover;
    position: relative;
    display: block;
    cursor: pointer;
    overflow: hidden; }
    @media (min-width: 1024px) {
      .jumbotron .jumbotron__viewport {
        -webkit-box-shadow: 0 3px 3px 0 #1a1a1a;
                box-shadow: 0 3px 3px 0 #1a1a1a;
        height: 500px;
        border-radius: 2px; }
        .jumbotron .jumbotron__viewport:hover .jumbotron__image {
          -webkit-transform: scale(1.05, 1.05);
                  transform: scale(1.05, 1.05); }
        .jumbotron .jumbotron__viewport:hover .jumbotron__overlay {
          opacity: 0.2; } }
    @media (min-width: 1300px) {
      .jumbotron .jumbotron__viewport {
        margin: 0 -40px; } }
  .jumbotron .jumbotron__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    -webkit-transition: 1s -webkit-transform;
    transition: 1s -webkit-transform;
    transition: 1s transform;
    transition: 1s transform, 1s -webkit-transform; }
  .jumbotron .jumbotron__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    opacity: 0;
    -webkit-transition: 0.5s opacity;
    transition: 0.5s opacity; }
  .jumbotron .jumbotron__content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - 30px);
    padding: 50px 15px 15px;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.8)), color-stop(80%, rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 80%); }
    @media (min-width: 1024px) {
      .jumbotron .jumbotron__content {
        width: calc(100% - 60px);
        padding: 200px 30px 30px; } }
  .jumbotron .jumbotron__title {
    font: 1.5rem/1.2em "Ek Mukta", sans-serif;
    margin: 0;
    padding: 0 0 10px;
    width: 100%;
    color: white;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.8); }
    @media (min-width: 1024px) {
      .jumbotron .jumbotron__title {
        font: 3rem/1.1em "Ek Mukta", sans-serif;
        width: 80%; } }
