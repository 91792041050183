.widget_sites {
  margin: 20px 15px;
  font-size: 0.9em; }
  .widget_sites .widget_sites__list {
    list-style: square;
    margin: 0;
    padding-left: 20px; }
  .widget_sites .widget_sites__element {
    margin: 15px 0; }
    @media (min-width: 1024px) {
      .widget_sites .widget_sites__element {
        margin: 0 0 5px 0; } }
