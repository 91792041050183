.mfp-content .mfp-figure::after {
  -webkit-box-shadow: 0 1px 2px 0 black;
          box-shadow: 0 1px 2px 0 black; }

.mfp-content .mfp-title {
  font-weight: lighter;
  font-family: "Lato", sans-serif;
  font-style: italic; }

[data-tippy-root] .tippy-content {
  font-size: 0.75rem !important; }
