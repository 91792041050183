.more {
  font-size: 0.83rem;
  text-decoration: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  background: #00548d;
  padding: 8px 32px;
  border-radius: 2px;
  color: white !important;
  text-align: right;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }
  .more.more--secondary {
    background: #444a53; }
  .more.more--small {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 20px;
    padding: 4px 16px; }
  .more.more--arrow-right::after {
    content: "\2192";
    padding-left: 8px; }
  .more:hover {
    opacity: 0.8; }
