.year_header {
  background: white;
  padding: 5px 10px;
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .year_header .year_header__year {
    font-size: 0.8em;
    font-weight: bolder; }
  .year_header .year_header__stats {
    font-size: 0.7em;
    color: #999;
    font-weight: bolder; }
