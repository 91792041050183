.canvas_tabs {
  height: 45px;
  border-radius: 4px;
  background: #fff;
  margin: 20px 0;
  padding: 10px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  .canvas_tabs .canvas_tabs__button {
    border-radius: 4px;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 2px 30px 0;
    margin: 0 5px;
    font-family: "Ek Mukta", sans-serif;
    font-size: 0.9rem;
    background: #f9f9f9;
    color: #333;
    -webkit-transition: background-color 0.5s;
    transition: background-color 0.5s; }
    .canvas_tabs .canvas_tabs__button:hover {
      background: #d9dde2; }
    .canvas_tabs .canvas_tabs__button--active {
      background: #9eaab7;
      color: white; }
      .canvas_tabs .canvas_tabs__button--active:hover {
        background: #9eaab7; }
