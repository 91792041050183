.mission_tools {
  background: white;
  border-radius: 2px;
  overflow: hidden;
  margin: 15px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px; }
  .mission_tools .mission_tools__subscribe {
    text-align: center;
    display: block;
    color: #1f3a4c;
    font-size: 0.83rem;
    margin: 0 10px 0 0; }
    .mission_tools .mission_tools__subscribe .more {
      padding: 10px 15px;
      border-radius: 4px; }
  .mission_tools .mission_tools__separator {
    border: 1px solid #eee;
    border-width: 1px 0 0 0;
    margin: 0; }
  .mission_tools .mission_tools__search {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .mission_tools .mission_tools__input {
    border: 1px solid #e5e5e5;
    border-right: none;
    border-radius: 4px 0 0 4px;
    padding: 5px 5px 5px 10px;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    width: 0;
    -webkit-appearance: none;
    font-family: "Lato", sans-serif;
    -webkit-transition: border-color 0.5s;
    transition: border-color 0.5s; }
    .mission_tools .mission_tools__input:focus {
      outline: none;
      border-color: #ccc; }
      .mission_tools .mission_tools__input:focus + *, .mission_tools .mission_tools__input:focus + * + * {
        border-color: #ccc; }
  .mission_tools .mission_tools__clear {
    color: #666;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    width: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
    -webkit-transition: border-color 0.5s;
    transition: border-color 0.5s; }
    .mission_tools .mission_tools__clear [data-search-clear] {
      display: none; }
  .mission_tools .mission_tools__button {
    border: 1px solid #e5e5e5;
    border-left: none;
    background: white;
    color: #00548d;
    border-radius: 0 4px 4px 0;
    width: 60px;
    -webkit-transition: border-color 0.5s;
    transition: border-color 0.5s; }
