.mission_label {
  padding: 5px 20px 5px;
  text-align: center;
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 2px;
  display: inline-block;
  line-height: 1em; }
  .mission_label--success {
    background: #218739; }
  .mission_label--partial_success {
    background: #dfec96;
    color: #424242; }
  .mission_label--failure {
    background: #c15151; }
  .mission_label--full {
    display: block;
    border-radius: 0; }
