.widget {
  background: #fff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
          box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
  margin-bottom: 15px;
  border-radius: 2px;
  overflow: hidden; }
  .widget .widget__header {
    text-transform: uppercase;
    height: 20px;
    font: 400 0.83rem/20px "Ek Mukta", sans-serif;
    padding: 10px 15px;
    border-bottom: 1px solid #eee; }
  .widget .widget__header_link {
    color: #424242; }
    .widget .widget__header_link:hover {
      text-decoration: underline; }
  .widget .widget__twitter_icon {
    color: #1da1f2; }
  .widget .widget__content {
    overflow: hidden; }
  .widget .widget__footer {
    text-align: right;
    padding: 10px 15px;
    border-top: 1px solid #eee; }
