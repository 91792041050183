.form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 20px 15px;
  font-family: "Lato", sans-serif;
  background: #fafafa;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 10px -15px 15px; }
  .form label {
    font-size: 0.83rem;
    line-height: 1.2em;
    font-weight: bolder;
    margin-bottom: 5px; }
  .form input, .form textarea {
    border: 1px solid #e5e5e5;
    border-bottom-width: 2px;
    -webkit-appearance: none;
    font-family: "Lato", sans-serif;
    font-size: 0.9rem;
    -webkit-transition: border-color 0.5s;
    transition: border-color 0.5s;
    padding: 15px;
    border-radius: 2px;
    margin-bottom: 15px;
    min-width: 400px;
    max-width: 100%;
    line-height: 1.6em; }
    .form input::-webkit-input-placeholder, .form textarea::-webkit-input-placeholder {
      color: #999; }
    .form input::-moz-placeholder, .form textarea::-moz-placeholder {
      color: #999; }
    .form input:-ms-input-placeholder, .form textarea:-ms-input-placeholder {
      color: #999; }
    .form input::-ms-input-placeholder, .form textarea::-ms-input-placeholder {
      color: #999; }
    .form input::placeholder, .form textarea::placeholder {
      color: #999; }
    .form input:focus, .form textarea:focus {
      border-color: #9eaab7;
      outline: none; }
  .form textarea {
    width: calc(100% - 30px);
    height: 200px; }
  .form input[type="submit"] {
    background: #00548d;
    border-radius: 2px;
    border: none;
    color: white;
    padding: 10px;
    min-width: 150px;
    margin-bottom: 0;
    -webkit-transition: background-color 0.5s;
    transition: background-color 0.5s;
    cursor: pointer; }
    .form input[type="submit"]:focus, .form input[type="submit"]:hover {
      background: #0072c0;
      outline: none; }
