.search_widget {
  float: right;
  width: 320px;
  position: relative;
  background: url(/theme/spacex/assets/96e73f2b0b3c30b6bc2dd07d991449e2.svg);
  height: 35px; }
  .search_widget .search_widget__input {
    font-size: 0.83rem;
    background: transparent;
    height: 35px;
    max-width: 230px;
    width: 100%;
    border: none;
    padding: 0 40px 0 10px;
    color: #d7d3d8;
    outline: none; }
    .search_widget .search_widget__input::-webkit-input-placeholder {
      color: #746976; }
    .search_widget .search_widget__input::-moz-placeholder {
      color: #746976; }
    .search_widget .search_widget__input:-ms-input-placeholder {
      color: #746976; }
    .search_widget .search_widget__input::-ms-input-placeholder {
      color: #746976; }
    .search_widget .search_widget__input::placeholder {
      color: #746976; }
  .search_widget .search_widget__button {
    background: none;
    border: none;
    color: #d7d3d8;
    margin-left: -45px;
    height: 35px;
    width: 40px;
    padding: 0;
    outline: 0;
    vertical-align: top;
    cursor: pointer;
    opacity: 0.8;
    -webkit-transition: 0.5s;
    transition: 0.5s; }
    .search_widget .search_widget__button:hover {
      opacity: 1; }
