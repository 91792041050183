.comments {
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
          box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
  border-top: 2px solid #00548d;
  border-radius: 2px;
  min-height: 150px;
  margin-bottom: 25px; }
  .comments .comments__disqus {
    margin: 15px; }
