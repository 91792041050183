.page_label {
  display: inline-block;
  background: url(/theme/spacex/assets/b8f1ee428739c447b4b9ba285df3095e.svg);
  background-size: cover;
  color: #f0eef0;
  font: 600 0.7rem/26px "Lato", sans-serif;
  letter-spacing: 0.1px;
  height: 25px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  -webkit-transition: -webkit-filter 0.5s;
  transition: -webkit-filter 0.5s;
  transition: filter 0.5s;
  transition: filter 0.5s, -webkit-filter 0.5s;
  position: relative;
  padding: 0 25px 0 45px; }
  @media (min-width: 1024px) {
    .page_label {
      padding: 0 15px 0 35px; } }
  .page_label.page_label--comments {
    z-index: 1; }
    .page_label.page_label--comments:hover {
      -webkit-filter: brightness(1.3);
              filter: brightness(1.3); }
  .page_label.page_label--updated {
    z-index: 0;
    background: url(/theme/spacex/assets/f441af8510d766f807336da75a0273f6.svg);
    background-size: cover;
    padding: 0 35px 0 35px;
    margin-right: -30px; }
    .page_label.page_label--updated:hover {
      -webkit-filter: brightness(1.1);
              filter: brightness(1.1); }
