.tags {
  overflow: hidden;
  display: block;
  vertical-align: middle; }
  .tags .tags__item {
    display: block;
    margin: 0 10px 10px 0;
    float: left; }
    @media (min-width: 1024px) {
      .tags .tags__item {
        margin: 0 5px 3px 0; } }

.tag {
  font: 400 0.75rem/21px "Lato", sans-serif;
  color: white;
  text-decoration: none;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  padding: 0 14px 0 14px;
  background: #444a53;
  border-radius: 21px;
  text-align: center;
  height: 21px;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  overflow: hidden;
  opacity: 1; }
  .tag:hover {
    opacity: 0.8; }
  .tag .tag__counter {
    background: #393e45;
    font-size: 0.9em;
    display: inline-block;
    padding: 0 7px 0 7px;
    margin: -1px -14px 0 5px;
    border-radius: 21px;
    height: 21px; }
