.mission_data {
  margin: 0 -15px 30px;
  padding: 15px;
  display: grid;
  grid-template-areas: "table" "patch";
  grid-template-rows: auto auto;
  grid-template-columns: auto;
  background: #f9f9f9;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  grid-gap: 20px; }
  @media (min-width: 1024px) {
    .mission_data {
      grid-template-areas: "table patch";
      grid-template-rows: auto;
      grid-template-columns: 55% calc(45% - 14px);
      grid-gap: 15px; } }
  .mission_data.mission_data--with_counter {
    grid-template-areas: "counter" "table" "patch";
    grid-template-rows: auto auto auto; }
    @media (min-width: 1024px) {
      .mission_data.mission_data--with_counter {
        grid-template-areas: "table patch" "counter counter";
        grid-template-rows: auto auto; } }
  .mission_data .mission_data__counter {
    grid-area: counter;
    margin: -15px -15px 0; }
    @media (min-width: 1024px) {
      .mission_data .mission_data__counter {
        margin: 0 -15px -15px; } }
  .mission_data .mission_data__table_box {
    grid-area: table; }
  .mission_data .mission_data__table {
    border: 1px solid #e0e0e0;
    padding: 5px;
    border-radius: 4px;
    background: white; }
  .mission_data .mission_data__image_box {
    grid-area: patch;
    text-align: center; }
  .mission_data .mission_data__image {
    max-width: 100%;
    max-height: 300px;
    cursor: pointer; }
    @media (min-width: 1024px) {
      .mission_data .mission_data__image {
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s; }
        .mission_data .mission_data__image:hover {
          opacity: 0.75; } }
