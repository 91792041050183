.mobile-navigation {
  background: #23292d;
  width: 70px;
  height: 70px;
  text-align: center;
  font-size: 30px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 0 0 0 4px; }
  .mobile-navigation .mobile-navigation__icon {
    position: relative;
    height: 4px;
    width: 25px; }
  .mobile-navigation .mobile-navigation__bar {
    width: 25px;
    height: 4px;
    background: #999;
    border-radius: 1.5px;
    display: block;
    -webkit-transition: opacity .1s ease .3s,top .1s ease .3s,-webkit-transform .3s;
    transition: opacity .1s ease .3s,top .1s ease .3s,-webkit-transform .3s;
    transition: transform .3s,opacity .1s ease .3s,top .1s ease .3s;
    transition: transform .3s,opacity .1s ease .3s,top .1s ease .3s,-webkit-transform .3s;
    position: absolute; }
  .mobile-navigation .mobile-navigation__bar-one {
    top: -8px; }
  .mobile-navigation .mobile-navigation__bar-two {
    top: 0; }
  .mobile-navigation .mobile-navigation__bar-three {
    top: 8px; }
  .mobile-navigation--active {
    background: #2e363c; }
    .mobile-navigation--active .mobile-navigation__bar {
      background: #c7a652;
      -webkit-transition: top 0.1s, opacity 0.1s, -webkit-transform 0.3s ease 0.1s;
      transition: top 0.1s, opacity 0.1s, -webkit-transform 0.3s ease 0.1s;
      transition: top 0.1s, opacity 0.1s, transform 0.3s ease 0.1s;
      transition: top 0.1s, opacity 0.1s, transform 0.3s ease 0.1s, -webkit-transform 0.3s ease 0.1s; }
    .mobile-navigation--active .mobile-navigation__bar-one {
      top: 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .mobile-navigation--active .mobile-navigation__bar-two {
      opacity: 0; }
    .mobile-navigation--active .mobile-navigation__bar-three {
      top: 0;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
  @media (min-width: 768px) {
    .mobile-navigation {
      display: none; } }
