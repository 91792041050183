.search_form {
  background: white;
  border-radius: 2px;
  overflow: hidden;
  margin: 0 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px; }
  .search_form + .archive .archive__title {
    margin-top: 30px; }
  .search_form .search_form__title {
    font-weight: bolder;
    font-family: "Ek Mukta", sans-serif;
    font-size: 1.1em;
    text-transform: uppercase; }
  .search_form .search_form__bar {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .search_form .search_form__input {
    border: 1px solid #e5e5e5;
    border-right: none;
    border-radius: 4px 0 0 4px;
    padding: 5px 5px 5px 10px;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-appearance: none;
    font-family: "Lato", sans-serif;
    -webkit-transition: border-color 0.5s;
    transition: border-color 0.5s;
    height: 30px; }
    .search_form .search_form__input:focus {
      outline: none;
      border-color: #ccc; }
      .search_form .search_form__input:focus + *, .search_form .search_form__input:focus + * + * {
        border-color: #ccc; }
  .search_form .search_form__button {
    border: 1px solid #e5e5e5;
    border-left: none;
    background: white;
    color: #00548d;
    border-radius: 0 4px 4px 0;
    width: 60px;
    -webkit-transition: border-color 0.5s;
    transition: border-color 0.5s; }
