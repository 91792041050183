.mission_row {
  background: #fff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
          box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
  position: relative;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
  border-radius: 2px;
  display: grid;
  border-top: 1px solid #c7a652;
  cursor: pointer;
  grid-template-areas: 'number icons' 'image image' 'title title' 'date date' 'result result' 'table table';
  grid-template-columns: 50% 50%;
  grid-auto-rows: auto; }
  @media (min-width: 1024px) {
    .mission_row:hover {
      background: #eee; }
      .mission_row:hover .mission_row__patch, .mission_row:hover .mission_row__icons {
        background: #e3e3e3; } }
  .mission_row.mission_row--finished {
    border-top: 1px solid #00548d; }
  @media (min-width: 1024px) {
    .mission_row {
      grid-template-areas: 'number image title result date rocket icons';
      grid-auto-rows: unset;
      grid-template-columns: 40px 100px 150px 110px 140px auto 30px;
      grid-template-rows: auto; } }
  @media (min-width: 1200px) {
    .mission_row {
      grid-template-columns: 40px 100px 200px 120px 160px auto 40px; } }
  .archive__list--missions .mission_row + .archive__title {
    margin-top: 10px; }
  .mission_row .mission_row__number {
    grid-area: number;
    font-size: 0.75rem;
    padding: 6px 10px 5px; }
    @media (min-width: 1024px) {
      .mission_row .mission_row__number {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; } }
  .mission_row .mission_row__icons {
    grid-area: icons;
    font-size: 0.83rem;
    text-align: right;
    padding: 6px 10px 5px;
    color: #00548d; }
    @media (min-width: 1024px) {
      .mission_row .mission_row__icons {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        border-left: 1px solid #eee;
        background: #fafafa;
        -webkit-transition: background-color 0.5s;
        transition: background-color 0.5s; } }
  .mission_row .mission_row__patch {
    grid-area: image;
    background: #fafafa;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    -webkit-transition: background-color 0.5s;
    transition: background-color 0.5s; }
  .mission_row .mission_row__title {
    grid-area: title;
    font-size: 1.25rem;
    font-family: "Ek Mukta", sans-serif;
    line-height: 1.2em;
    padding: 15px 0 3px;
    text-align: center;
    color: #333;
    display: block; }
    @media (min-width: 1024px) {
      .mission_row .mission_row__title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 10px 10px 10px 15px;
        font-size: 1rem;
        text-align: left; } }
  .mission_row .mission_row__date {
    grid-area: date;
    font-weight: bolder;
    font-size: 0.83rem;
    padding: 0 15px 5px;
    text-align: center; }
    @media (min-width: 1024px) {
      .mission_row .mission_row__date {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        font-weight: normal;
        text-align: left;
        font-size: 0.75rem;
        padding: 0 15px; } }
  .mission_row .mission_row__time {
    display: inline; }
    @media (min-width: 1024px) {
      .mission_row .mission_row__time {
        display: none; } }
  .mission_row .mission_row__result {
    grid-area: result;
    text-align: center;
    padding: 0; }
    @media (min-width: 1024px) {
      .mission_row .mission_row__result {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; } }
  .mission_row .mission_row__result_wrapper {
    padding: 2px 0 7px; }
    @media (min-width: 1024px) {
      .mission_row .mission_row__result_wrapper {
        padding: 0; } }
  .mission_row .mission_row__rocket {
    grid-area: rocket;
    display: none;
    font-size: 0.75rem;
    padding: 0 15px 5px; }
    @media (min-width: 1024px) {
      .mission_row .mission_row__rocket {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        padding: 0 15px; } }
  .mission_row .mission_row__table {
    grid-area: table;
    text-align: left;
    padding: 10px 15px 11px;
    border-top: 1px solid #eee;
    margin-top: 10px; }
    @media (min-width: 1024px) {
      .mission_row .mission_row__table {
        display: none; } }
  .mission_row .mission_row__image {
    background: 50% 50% no-repeat;
    background-size: contain; }
    .mission_row .mission_row__image.mission_row__image--cover {
      background-size: cover;
      height: 230px;
      background-origin: content-box; }
      @media (min-width: 1024px) {
        .mission_row .mission_row__image.mission_row__image--cover {
          height: 70px;
          border-left: 1px solid #eee;
          border-right: 1px solid #eee; } }
    .mission_row .mission_row__image.mission_row__image--logo {
      background-size: contain;
      height: 200px;
      padding: 15px;
      background-origin: content-box; }
      @media (min-width: 1024px) {
        .mission_row .mission_row__image.mission_row__image--logo {
          height: 60px;
          padding: 5px;
          border-left: 1px solid #eee;
          border-right: 1px solid #eee; } }
    @media (min-width: 768px) {
      .mission_row .mission_row__image {
        height: 70px; } }
