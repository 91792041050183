.article_thumbnail {
  margin: 0;
  overflow: hidden;
  display: block;
  height: 100%;
  background-size: cover;
  -webkit-transition: opacity 0.5s, -webkit-transform 1s;
  transition: opacity 0.5s, -webkit-transform 1s;
  transition: transform 1s, opacity 0.5s;
  transition: transform 1s, opacity 0.5s, -webkit-transform 1s;
  -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
  position: relative;
  z-index: 2; }
  @media (min-width: 1024px) {
    .article_thumbnail {
      border-radius: 2px; } }
  .article_thumbnail .article_thumbnail__image {
    background: center center no-repeat white;
    background-size: cover;
    display: block;
    height: 0;
    padding-bottom: 68.75%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    overflow: hidden;
    -webkit-box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3); }
    .article_thumbnail .article_thumbnail__image--cover {
      background-size: cover; }
    .article_thumbnail .article_thumbnail__image--logo {
      background-size: contain;
      height: calc(100% - 20px);
      padding: 10px;
      background-origin: content-box; }
