.article_card {
  background: #fff;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
          box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
  border-top: 2px solid #00548d;
  border-radius: 2px;
  cursor: pointer;
  display: grid;
  grid-template-areas: "labels" "thumb" "content";
  grid-template-rows: 25px auto 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  margin: 0 auto; }
  .article_card .article_card__thumbnail {
    grid-area: thumb;
    margin-top: -25px; }
    @media (min-width: 1024px) {
      .article_card .article_card__thumbnail .article_thumbnail {
        border-radius: 0; } }
  @media (min-width: 1024px) {
    .article_card:hover .article_thumbnail {
      opacity: 0.8; }
      .article_card:hover .article_thumbnail .article_thumbnail__image {
        -webkit-transform: scale3d(1.05, 1.05, 1);
                transform: scale3d(1.05, 1.05, 1); } }
  .article_card .article_card__content {
    padding: 10px 20px 20px 20px;
    grid-area: content;
    text-overflow: ellipsis;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .article_card .article_card__labels {
    grid-area: labels;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    z-index: 2;
    -webkit-filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.6));
            filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.6)); }
  .article_card .article_card__title {
    font: 400 1.25rem/1.25em "Ek Mukta", sans-serif;
    margin: 10px 0 0; }
    @media (min-width: 1024px) {
      .article_card .article_card__title {
        font: 400 1.1375rem/1.25em "Ek Mukta", sans-serif; } }
    .article_card .article_card__title .article_card__title-link {
      color: #333;
      text-decoration: none;
      -webkit-transition: opacity 0.5s;
      transition: opacity 0.5s;
      display: block; }
      .article_card .article_card__title .article_card__title-link:hover {
        opacity: 0.6; }
  .article_card .article_card__date {
    color: #444a53;
    padding: 2px 0;
    font: 400 0.75rem "Lato", sans-serif;
    margin-top: 1px;
    margin-bottom: 8px; }
  .article_card .article_card__excerpt {
    font: 400 0.83rem/1.6em "Lato", sans-serif;
    text-align: left;
    color: #666;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
  .article_card .article_card__button {
    background: #00548d;
    color: white;
    padding: 5px 20px;
    border-radius: 2px;
    font-size: 0.9em;
    float: right;
    display: none; }
  .article_card .article_card__category,
  .article_card .article_card__authors {
    display: none; }
