.widget_popular {
  list-style: none;
  padding: 0;
  margin: 0; }
  .widget_popular .widget_popular__element {
    border-top: 1px solid #eee; }
    .widget_popular .widget_popular__element:first-child {
      border-top: none; }
  .widget_popular .widget_popular__article {
    padding: 10px;
    display: grid;
    grid-template-areas: "thumb content";
    grid-template-columns: 30% calc(70% - 10px);
    grid-template-rows: 1fr;
    grid-gap: 10px;
    color: #333; }
    .widget_popular .widget_popular__article:hover {
      background: #fafafa; }
      .widget_popular .widget_popular__article:hover .widget_popular__image {
        opacity: 1; }
  .widget_popular .widget_popular__content {
    grid-area: content;
    font: 500 0.83rem/1.2em "Ek Mukta", sans-serif, serif; }
  .widget_popular .widget_popular__image {
    grid-area: thumb;
    background: center center no-repeat;
    background-size: cover;
    height: 0;
    padding-bottom: 68.75%;
    border-radius: 2px;
    opacity: 0.8;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s; }
    .widget_popular .widget_popular__image--logo {
      background-size: contain; }
  .widget_popular .widget_popular__title {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 3px; }
  .widget_popular .widget_popular__info {
    font-size: 0.75rem;
    color: #444a53; }
