.footer {
  color: #999;
  width: 100%; }
  @media (min-width: 1024px) {
    .footer {
      bottom: 0;
      position: absolute;
      z-index: 1; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .footer {
      background: #181c1f; } }
  .footer .footer__container {
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
    position: relative;
    padding: 0;
    display: grid;
    grid-template-areas: "disclaimer" "center";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto; }
    @media (min-width: 768px) {
      .footer .footer__container {
        max-width: 720px; } }
    @media (min-width: 1024px) {
      .footer .footer__container {
        max-width: 990px; } }
    @media (min-width: 1200px) {
      .footer .footer__container {
        max-width: 1140px; } }
    @media (min-width: 768px) {
      .footer .footer__container {
        max-width: unset; } }
    @media (min-width: 1024px) {
      .footer .footer__container {
        max-width: 990px; } }
    @media (min-width: 1200px) {
      .footer .footer__container {
        max-width: 1140px; } }
    @media (min-width: 1024px) {
      .footer .footer__container {
        grid-template-areas: "disclaimer center dragon";
        grid-template-columns: 30% 40% 30%;
        grid-template-rows: 144px; } }
  @media (min-width: 1024px) {
    .footer .footer__dragon {
      background: url(/theme/spacex/assets/d685f4c9e518ff932f66dbabe4588a13.png) no-repeat;
      width: 546px;
      height: 334px;
      position: absolute;
      bottom: 0;
      right: -100px; } }
  .footer .footer__disclaimer {
    grid-area: disclaimer;
    font-size: 0.7rem;
    border-bottom: 1px solid #393838;
    background: #23292d;
    padding: 15px; }
    .footer .footer__disclaimer p {
      margin: 6px 0; }
    @media (min-width: 1024px) {
      .footer .footer__disclaimer {
        background: transparent;
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; }
        .footer .footer__disclaimer p {
          margin: 3px 0; } }
  .footer .footer__disclaimer_title {
    font-size: 0.75rem;
    margin: 5px 0;
    font-weight: bolder;
    text-transform: uppercase; }
  .footer .footer__center {
    grid-area: center;
    background: #181c1f;
    text-align: center;
    padding: 45px 15px; }
    @media (min-width: 1024px) {
      .footer .footer__center {
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; } }
  .footer .footer__logo {
    background: url(/theme/spacex/assets/139d72884da4c44cab814d3ccc6497d8.svg) center center no-repeat;
    background-size: contain;
    height: 30px;
    width: 200px;
    display: inline-block;
    margin: 0 auto; }
  .footer .footer__copyrights {
    font-size: 0.83rem;
    margin-top: 4px; }
    .footer .footer__copyrights a {
      color: #d7d3d8; }
  .footer .footer__copyrights_row {
    margin-bottom: 4px; }
