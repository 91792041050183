.topbar {
  background: #181c1f;
  position: fixed;
  z-index: 150;
  top: 0;
  width: 100%;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5); }
  @media (min-width: 768px) {
    .topbar {
      position: relative;
      z-index: 100; } }
  .topbar .topbar__container {
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
    height: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding: 0; }
    @media (min-width: 768px) {
      .topbar .topbar__container {
        max-width: 720px; } }
    @media (min-width: 1024px) {
      .topbar .topbar__container {
        max-width: 990px; } }
    @media (min-width: 1200px) {
      .topbar .topbar__container {
        max-width: 1140px; } }
    @media (min-width: 768px) {
      .topbar .topbar__container {
        height: 90px; } }
  .topbar .topbar__logo {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media (min-width: 768px) {
      .topbar .topbar__logo {
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start; } }
  .topbar .topbar__logo_link {
    background: url(/theme/spacex/assets/139d72884da4c44cab814d3ccc6497d8.svg) center center no-repeat;
    background-size: contain;
    display: block;
    width: 252px;
    height: 22px;
    max-width: 70%; }
  .topbar .topbar__search {
    display: none;
    text-align: right;
    padding-top: calc((70px - 32px) / 2); }
    @media (min-width: 768px) {
      .topbar .topbar__search {
        display: block;
        padding-top: calc((90px - 32px) / 2); } }
