.read_more {
  display: block;
  margin: 25px 0;
  padding: 10px 10px 10px 15px;
  color: black;
  font-size: 0.9rem;
  line-height: 1.6em;
  border-radius: 2px;
  background: #00548d;
  border: 1px solid #00548d;
  color: white !important;
  text-align: center;
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s; }
  .read_more:hover {
    background: #0072c0; }
