.next_mission {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
  overflow: hidden;
  -webkit-box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
          box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
  border-top: 2px solid #00548d;
  display: grid;
  grid-template-areas: "thumbnail header" "thumbnail info" "counter counter" "footer footer";
  grid-template-rows: auto 1fr auto auto;
  grid-template-columns: 100px auto; }
  @media (min-width: 1024px) {
    .next_mission {
      display: none; } }
  .next_mission .next_mission__header {
    font-size: 0.75rem;
    padding: 5px;
    margin: 0;
    grid-area: header;
    border-bottom: 1px solid #eee;
    font-weight: bolder;
    text-align: center; }
  .next_mission .next_mission__thumbnail {
    grid-area: thumbnail;
    display: block;
    overflow: hidden;
    border-right: 1px solid #eee;
    background: #fafafa; }
  .next_mission .next_mission__image {
    background: 50% 50% no-repeat;
    background-size: contain;
    height: 0;
    padding-bottom: 100%; }
    .next_mission .next_mission__image--cover {
      background-size: cover; }
    .next_mission .next_mission__image--logo {
      background-size: contain;
      padding: 10px;
      height: calc(100% - 20px);
      min-height: 80px;
      background-origin: content-box; }
  .next_mission .next_mission__info {
    font-size: 0.83rem;
    grid-area: info;
    padding: 15px;
    color: #424242;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .next_mission .next_mission__name {
    font: 600 1.25rem/1.6rem "Ek Mukta", sans-serif;
    text-align: center;
    display: block;
    color: #333;
    margin-top: 3px; }
  .next_mission .next_mission__counter {
    grid-area: counter; }
  .next_mission .next_mission__footer {
    font-size: 0.75rem;
    padding: 5px;
    grid-area: footer;
    border-bottom: 1px solid #eee;
    font-weight: bolder;
    text-align: center;
    display: block;
    color: #333; }
