.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important; }

html {
  height: 100%; }

body {
  background: #efefef;
  font: 16px "Lato", sans-serif;
  color: #424242;
  padding-top: 70px;
  overflow-x: hidden;
  height: 100%;
  max-width: 100%;
  min-height: 100%; }
  body.body-stale {
    overflow: hidden; }
  @media (min-width: 768px) {
    body {
      padding-top: 0; } }

.wrapper {
  position: relative; }
  @media (min-width: 1024px) {
    .wrapper {
      min-height: calc(100% - 144px);
      padding-bottom: 144px; } }

a {
  color: #00548d;
  text-decoration: none;
  outline: 0; }

#shooting-stars {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  display: none; }

.screen-reader-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.pswp img {
  max-width: none;
  -o-object-fit: contain;
     object-fit: contain; }

.edit_link {
  display: none; }
  .edit_link.edit_link--active {
    display: inline; }
  .edit_link a {
    color: inherit; }
