.article_box {
  background: #fff;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
          box-shadow: 0 1px 2px 0 rgba(66, 65, 65, 0.4);
  border-top: 2px solid #00548d;
  border-radius: 2px;
  cursor: pointer;
  display: grid;
  grid-template-areas: "labels" "thumb" "content";
  grid-template-rows: 25px auto 1fr;
  grid-template-columns: 1fr;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .article_box {
      max-width: unset; } }
  @media (min-width: 1024px) {
    .article_box {
      grid-template-areas: "thumb labels" "thumb content";
      grid-template-rows: 25px 219px;
      grid-template-columns: 280px 1fr;
      max-width: 100%; } }
  @media (min-width: 1200px) {
    .article_box {
      grid-template-columns: 350px 1fr; } }
  .article_box .article_box__anchor {
    position: absolute;
    top: -15px; }
  .article_box .article_box__thumbnail {
    margin-top: -25px;
    grid-area: thumb; }
    @media (min-width: 1024px) {
      .article_box .article_box__thumbnail {
        padding: 12px 18px 12px 12px;
        height: 220px;
        margin-top: 0; }
        .article_box .article_box__thumbnail .article_thumbnail__image {
          padding-bottom: 0;
          height: 100%; } }
  @media (min-width: 1024px) {
    .article_box:hover .article_thumbnail {
      opacity: 0.8; }
      .article_box:hover .article_thumbnail .article_thumbnail__image {
        -webkit-transform: scale3d(1.05, 1.05, 1);
                transform: scale3d(1.05, 1.05, 1); } }
  .article_box .article_box__content {
    padding: 10px 20px 20px 20px;
    grid-area: content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    max-height: 250px; }
    @media (min-width: 1024px) {
      .article_box .article_box__content {
        padding: 10px 20px 20px 0;
        max-height: unset; } }
  .article_box .article_box__labels {
    grid-area: labels;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    z-index: 2;
    -webkit-filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.6));
            filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.6)); }
    @media (min-width: 1024px) {
      .article_box .article_box__labels {
        -webkit-filter: none;
                filter: none; } }
  .article_box .article_box__title {
    font: 400 1.25rem/1.25em "Ek Mukta", sans-serif;
    margin: 10px 0 0; }
    @media (min-width: 1024px) {
      .article_box .article_box__title {
        font: 500 1.25rem/1.25em "Ek Mukta", sans-serif;
        margin: 0 0 0; } }
    .article_box .article_box__title .article_box__title-link {
      color: #333;
      text-decoration: none;
      -webkit-transition: opacity 0.5s;
      transition: opacity 0.5s;
      display: block; }
      .article_box .article_box__title .article_box__title-link:hover {
        opacity: 0.6; }
  .article_box .article_box__date {
    color: #444a53;
    padding: 2px 0;
    font: 400 0.75rem "Lato", sans-serif;
    margin-top: 1px;
    margin-bottom: 8px; }
  .article_box .article_box__excerpt {
    font: 400 0.83rem/1.6em "Lato", sans-serif;
    text-align: left;
    color: #666;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    overflow: hidden;
    position: relative;
    max-height: 200px; }
    .article_box .article_box__excerpt::before {
      content: " ";
      position: absolute;
      bottom: 0;
      height: 100px;
      width: 100%;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, rgba(255, 255, 255, 0)), color-stop(95%, white));
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, white 95%); }
  .article_box .article_box__button {
    background: #00548d;
    color: white;
    padding: 5px 20px;
    border-radius: 2px;
    font-size: 0.9em;
    float: right;
    display: none; }
  .article_box .article_box__category,
  .article_box .article_box__authors {
    display: none; }
