.mission_crew {
  margin: 20px 0 30px; }
  .mission_crew .mission_crew__member {
    padding: 20px;
    background: #eee;
    margin-bottom: 10px;
    border-radius: 2px;
    display: grid;
    grid-template-areas: "thumb" "info";
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-gap: 10px; }
    @media (min-width: 768px) {
      .mission_crew .mission_crew__member {
        grid-template-areas: "info thumb";
        grid-template-columns: auto 150px;
        grid-template-rows: auto;
        grid-gap: 20px; } }
  .mission_crew .mission_crew__photo {
    grid-area: thumb;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    margin: 0 auto;
    background-position: top center;
    background-size: cover;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3);
    overflow: hidden; }
    .mission_crew .mission_crew__photo img {
      max-width: 100%;
      cursor: pointer; }
      @media (min-width: 1024px) {
        .mission_crew .mission_crew__photo img {
          -webkit-transition: opacity 0.5s;
          transition: opacity 0.5s; }
          .mission_crew .mission_crew__photo img:hover {
            opacity: 0.75; } }
    @media (min-width: 768px) {
      .mission_crew .mission_crew__photo {
        height: 150px;
        width: 150px;
        padding-bottom: 0; } }
  .mission_crew .mission_crew__info {
    grid-area: info; }
  .mission_crew .mission_crew__name {
    font: bold 1rem "Ek Mukta", sans-serif;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px; }
    @media (min-width: 768px) {
      .mission_crew .mission_crew__name {
        text-align: left;
        margin: 0; } }
  .mission_crew .mission_crew__description {
    font-size: 0.83rem;
    line-height: 1.6em; }
