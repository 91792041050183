@-webkit-keyframes textPlaceholderAnimation {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes textPlaceholderAnimation {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.text_placeholder {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: textPlaceholderAnimation;
          animation-name: textPlaceholderAnimation;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f7f8;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eee), color-stop(18%, #ddd), color-stop(33%, #eee));
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 800px 104px;
  height: 16px;
  width: 200px;
  display: inline-block;
  position: relative;
  vertical-align: middle; }
