.archive .archive__container {
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  padding: 0;
  position: relative;
  margin-bottom: 15px; }
  @media (min-width: 768px) {
    .archive .archive__container {
      max-width: 720px; } }
  @media (min-width: 1024px) {
    .archive .archive__container {
      max-width: 990px; } }
  @media (min-width: 1200px) {
    .archive .archive__container {
      max-width: 1140px; } }
  @media (min-width: 1024px) {
    .archive .archive__container {
      margin-bottom: 30px; } }

.archive .archive__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.archive .archive__title {
  font: 300 1.4rem/1em "Ek Mukta", sans-serif;
  color: #1f3a4c;
  margin: 15px 0; }
  .archive .archive__title.archive__title--second {
    font: 300 1.1375rem/1em "Ek Mukta", sans-serif;
    margin: 5px 0; }

.archive .archive__tabs {
  overflow: hidden;
  margin-bottom: 20px;
  text-align: center; }
  @media (min-width: 768px) {
    .archive .archive__tabs {
      position: absolute;
      top: 0;
      right: 0;
      display: inline; } }

.archive .archive__list {
  display: grid;
  grid-template-areas: "item";
  grid-auto-rows: auto;
  grid-gap: 15px; }
  @media (min-width: 768px) {
    .archive .archive__list {
      grid-template-areas: "item item"; }
      .archive .archive__list .archive__title, .archive .archive__list .year_header {
        grid-area: auto / item; } }
  @media (min-width: 1024px) {
    .archive .archive__list {
      grid-template-areas: "item"; } }
  .archive .archive__list.archive__list--missions {
    margin-top: 20px;
    -webkit-transition: opacity 0.2s, -webkit-transform 0.3s;
    transition: opacity 0.2s, -webkit-transform 0.3s;
    transition: opacity 0.2s, transform 0.3s;
    transition: opacity 0.2s, transform 0.3s, -webkit-transform 0.3s;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
    .archive .archive__list.archive__list--missions.archive__list--hidden {
      opacity: 0;
      -webkit-transform: translateY(50px);
              transform: translateY(50px); }
    .archive .archive__list.archive__list--missions .archive__title--second {
      margin: 15px 0 0; }

.archive .archive__more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

.archive .archive__cards_grid {
  display: grid;
  grid-template-areas: "card";
  grid-auto-columns: 1fr;
  grid-auto-rows: auto;
  grid-gap: 15px; }
  @media (min-width: 768px) {
    .archive .archive__cards_grid {
      grid-template-areas: "card card";
      grid-auto-columns: 1fr 1fr; } }
  @media (min-width: 1200px) {
    .archive .archive__cards_grid {
      grid-template-areas: "card card card";
      grid-auto-columns: 1fr 1fr 1fr; } }

.archive .archive__button {
  float: right;
  height: 30px;
  line-height: 0;
  text-align: center;
  display: block;
  color: #a6a6a6;
  text-transform: uppercase;
  font-size: 0.9em;
  -webkit-transition: 0.5s;
  transition: 0.5s; }
  .archive .archive__button:hover {
    color: #010304; }

.archive .archive__empty_search {
  font: 1.8em/1em "Ek Mukta", sans-serif;
  color: #1f3a4c;
  text-align: center;
  margin: 20px 20px 60px; }

.archive .archive__anchor + * {
  margin-top: -15px; }

.archive .archive__collapsed {
  display: none; }
  .archive .archive__collapsed.collapsible-open {
    display: grid; }
