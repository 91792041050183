.page_title {
  font: 300 42px/1.1em "Ek Mukta", sans-serif;
  margin: 40px 0 20px; }
  .page_title.page_title--card {
    font: 400 18px/22px "Ek Mukta", sans-serif;
    margin: 20px 0 0; }
  .page_title .page_title__link {
    color: #333;
    text-decoration: none;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    display: block; }
    .page_title .page_title__link:hover {
      opacity: 0.6; }
